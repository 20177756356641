@import url('https://fonts.cdnfonts.com/css/fsalbert');


* {
  box-sizing: border-box;
}

.bodyVines {
  margin-top: 40px;
  font-family: 'fsalbert',sans-serif;
  display: flex;
  justify-content: center;
  background-image: url("/public/img/vina/wineBack.png");
  background-color: rgba(255, 255, 255, 0.5) ;
  background-size:auto; /* Optional: Adjusts the size of the background image */
  background-position: center; /* Optional: Positions the background image */
  
  
.card {
  
  max-width: 1200px; /* Adjust the maximum width as needed */
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.3s;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.photo {
  width: 100%;
  padding: 30px;
  text-align: center;
  float: left;
}

.photo img {
  max-width: 100%;
  height: 350px;
}

.photoRight {
  width: 100%;
  padding: 30px;
  text-align: center;
  float: right;
}
.photoRight img {
  max-width: 100%;
  height: 350px;
}

.description {
  padding: 30px;
  text-align: center;
}
.descriptionRight {
  padding: 30px;
  text-align: center;
}

h1 {
  color: #515151;
  font-weight: 300;
  padding-top: 15px;
  margin: 0;
  font-size: 30px;
  font-family: 'fsalbert',sans-serif;
}

h2 {
  color: #515151;
  margin: 0;
  text-transform: none;
  font-weight: 700;
  font-size: 30px;
  font-family: 'fsalbert',sans-serif;

}

h4 {
  margin: 0;
  color: #7B0323;
  font-weight: 400;
  font-size: 20px;
  font-style: italic;
  font-family: 'fsalbert',sans-serif;
}

p {
  font-size: 18px;
  line-height: 20px;
  color: #727272;
  padding: 20px 0;
  margin: 0;
  font-weight: 400;
  width: 90%; 
}
.pRight {
  font-size: 18px;
  line-height: 20px;
  color: #727272;
  padding: 20px 0;
  margin: 0;
  font-weight: 400;
  width: 100%;
}

button {
  outline: 0;
  border: 0;
  background: none;
  border: 1px solid #d9d9d9;
  padding: 8px 0px;
  margin-top: auto;
  color: #515151;
  text-transform: uppercase;
  width: 150px;
  font-family: inherit;
  margin-right: 15px;
  transition: all 0.3s ease;
  font-weight: 500;
  
}

button:hover {
  background: darken(white, 2%);
  border: 1px solid #aedaa6;
  color: #aedaa6;
  cursor: pointer;
}


@media (min-width: 768px) {
  .card {
    flex-direction: row;
  }
  .photo {
    width: 45%;
    order: 0;
  }
  .photoRight {
    width: 45%;
    order: 2;
  }

  .description {
    width: 55%;
    padding-left: 30px;
    text-align: left;
    margin-top: 5%;
  }
  .descriptionRight {
    width: 55%;
    padding-left: 30px;
    text-align: right;
    margin-top: 5%;
    margin-left: 5%;
  }
  button {
    margin-top: 20px;

  }
  
}

}